import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '@atoms/Button/Button';
import Divider from '@atoms/Divider/Divider';
import Section from '@atoms/Section/Section';
import Title from '@atoms/Title/Title';
import Footer from '@components/Footer/Footer';
import HeaderMinimal from '@components/HeaderMinimal/HeaderMinimal';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import Layout from '@components/layout';
import Seo from '@components/seo';
import SingleAccordion from '@components/SingleAccordion/SingleAccordion';
import { useFetchFaq } from '@hooks';
import { withLocale } from '@hocs';

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        headerMobileImage: file(
            relativePath: { eq: "faq/header-FAQ-mobile.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        headerDesktopImage: file(
            relativePath: { eq: "faq/header-FAQ-desktop.jpg" }
        ) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 1440) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        aepsyCms {
            faqsDE: faqs(
                locale: "de-CH"
                filters: { key: { eq: "FOR_PSYCHOLOGIST" } }
            ) {
                data {
                    attributes {
                        ...FaqField
                    }
                }
            }
            faqsEN: faqs(
                locale: "en"
                filters: { key: { eq: "FOR_PSYCHOLOGIST" } }
            ) {
                data {
                    attributes {
                        ...FaqField
                    }
                }
            }
            faqsFR: faqs(
                locale: "fr-CH"
                filters: { key: { eq: "FOR_PSYCHOLOGIST" } }
            ) {
                data {
                    attributes {
                        ...FaqField
                    }
                }
            }
            faqsIT: faqs(
                locale: "it"
                filters: { key: { eq: "FOR_PSYCHOLOGIST" } }
            ) {
                data {
                    attributes {
                        ...FaqField
                    }
                }
            }
            faqsUserDE: faqs(
                locale: "de-CH"
                filters: { key: { eq: "FOR_USER" } }
            ) {
                data {
                    attributes {
                        ...FaqField
                    }
                }
            }
            faqsUserEN: faqs(
                locale: "en"
                filters: { key: { eq: "FOR_USER" } }
            ) {
                data {
                    attributes {
                        ...FaqField
                    }
                }
            }
            faqsUserFR: faqs(
                locale: "fr-CH"
                filters: { key: { eq: "FOR_USER" } }
            ) {
                data {
                    attributes {
                        ...FaqField
                    }
                }
            }
            faqsUserIT: faqs(
                locale: "it"
                filters: { key: { eq: "FOR_USER" } }
            ) {
                data {
                    attributes {
                        ...FaqField
                    }
                }
            }
        }
    }
`;

const FAQPage: React.FC<any> = ({ data }) => {
    const { t } = useTranslation();
    const { faq: faqForTherapist } = useFetchFaq({
        query: data,
    });

    const { faq: faqForUser } = useFetchFaq({
        query: data,
        name: 'faqsUser',
    });

    const static_images_sources = [
        data.headerMobileImage.childImageSharp.fluid,
        {
            ...data.headerDesktopImage.childImageSharp.fluid,
            media: `(min-width: 750px)`,
        },
    ];

    return (
        <Layout>
            <Seo title="FAQ | Aepsy" />
            <div className="global_theme-green">
                <HeaderNav theme="white" />
                <Section theme="light">
                    <HeaderMinimal
                        theme="light"
                        title={t('faq.page.title')}
                        imageSrc={static_images_sources}
                        btnPrimary={
                            <AnchorLink offset={() => 40} href="#mehr">
                                <Button
                                    variant="primary"
                                    theme="white"
                                    label={t('learn.more')}
                                    size="l"
                                />
                            </AnchorLink>
                        }
                    />
                </Section>

                <div id="mehr">
                    <Section spacing="m" theme="light" container="short">
                        {/* FAQ */}
                        <Section>
                            <Title
                                tag="h2"
                                size="xxl"
                                align="center"
                                theme="dark"
                            >
                                {t('faq.for.user.title')}
                            </Title>
                            <Divider invisible spacing="m" />
                            <SingleAccordion
                                data={faqForUser}
                                comingFrom="buchen"
                            />
                        </Section>
                    </Section>
                    <Section spacing="m" theme="light" container="short">
                        <Section>
                            <Title
                                tag="h2"
                                size="xxl"
                                align="center"
                                theme="dark"
                            >
                                {t('faq.for.psychologist.title')}
                            </Title>
                            <Divider invisible spacing="xl" />
                            <SingleAccordion
                                data={faqForTherapist}
                                comingFrom="therapist"
                            />
                        </Section>
                    </Section>
                </div>

                {/* more Questions */}
                <Section container="short" spacing="l">
                    <Title tag="h3" size="xl" align="center" theme="dark">
                        {t('faq.more.questions')}
                    </Title>
                    <Divider invisible spacing="s" />
                    <Title tag="p" size="m" align="center">
                        {t('faq.contact.us.at')}
                    </Title>
                    <div className="g_center">
                        <a href="mailto:hey@aepsy.com">
                            <Button
                                label="hey@aepsy.com"
                                theme="highlighted"
                                size="l"
                            />
                        </a>
                    </div>
                </Section>

                {/* Footer */}
                <Footer variant="extended" />
            </div>
        </Layout>
    );
};

export default withLocale(FAQPage);
